<template>
  <div style="height: inherit">
    <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <!-- <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" /> -->
                <div class="search-results" v-if="filteredProductsCount > 0">{{ filteredProductsCount }} {{ $t('shop.result_found') }}</div>
              </div>
              <div class="view-options d-flex">
                <!-- Sort Button -->
                <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="$t(selectedSortType.value)" right variant="primary">
                  <b-dropdown-item v-for="sortOption in shopSortTypes" :key="sortOption.value" @click="changeSortType(sortOption)">
                    {{ $t(sortOption.value) }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="search" :placeholder="$t('shop.search_placeholder')" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <section class="list-view" v-if="filteredProducts.length > 0">
        <b-card v-for="(product, index) in filteredProducts" :key="`product_${index}_${product.product_code}`" no-body>
          <!-- Product Details -->
          <b-card-body>
            <validation-observer v-slot="{ invalid }">
              <b-row class="w-100 m-0">
                <b-col cols="12" lg="5" xl="3" class=" d-flex justify-content-center align-items-center mb-2 mb-lg-0">
                  <div class="product-image-div mr-md-1">
                    <div class="product-is-new bg-light-success rounded" v-if="product.is_new_product === 1" v-html="$t('shop.is_new')" />
                    <b-img class="cursor-pointer product-image" :src="getProductImage(product)" @click="openProduct(product.product_id)" />
                  </div>
                </b-col>
                <b-col cols="12" lg="7" xl="9">
                  <b-row>
                    <!-- Ürün Kodu -->
                    <b-col cols="12" md="4">
                      <b-form-group :label="$t('product.code')" :label-for="`product-code-${product.product_id}`">
                        <b-form-input :id="`product-code-${product.product_id}`" v-model="product.product_code" disabled />
                      </b-form-group>
                    </b-col>
                    <!-- Barkod -->
                    <b-col cols="8" md="6">
                      <b-form-group :label="$t('product.barcode')" :label-for="`product-barcode-${product.product_id}`">
                        <validation-provider #default="{ errors }" :name="$t('product.barcode')" rules="min:3|max:16">
                          <b-form-input :id="`product-barcode-${product.product_id}`" v-model="product.barcode" :state="errors.length > 0 ? false : null" :placeholder="$t('product.barcode_placeholder')" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Aktif&Pasif -->
                    <b-col cols="4" md="2">
                      <b-form-group :label="$t('product.state')" :label-for="`product-state-${product.product_id}`">
                        <b-form-checkbox :checked="!product.temp_is_passive" :id="`product-state-${product.product_id}`" switch inline @change="updateProductState(product)">
                          <span class="d-none d-lg-block">{{ product.temp_is_passive ? $t('product.passive') : $t('product.active') }}</span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <!-- Ürün Adı -->
                    <b-col cols="12">
                      <b-form-group :label="$t('product.name')" :label-for="`product-name-${product.product_id}`">
                        <validation-provider #default="{ errors }" :name="$t('product.name')" rules="required|min:3">
                          <b-form-input :id="`product-name-${product.product_id}`" v-model="product.product_name" :state="errors.length > 0 ? false : null" :placeholder="$t('product.name_placeholder')" />
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Özellik Kodu -->
                    <b-col cols="12" md="6">
                      <b-form-group :label="$t('product.attribute_code')" :label-for="`product-atrribute-code-${product.product_id}`">
                        <validation-provider #default="{ errors }" :name="$t('product.attribute_code')" rules="min:3">
                          <b-form-input :id="`product-atrribute-code-${product.product_id}`" v-model="product.attribute_code" :state="errors.length > 0 ? false : null" :placeholder="$t('product.attribute_code_placeholder')" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Özellik Adı -->
                    <b-col cols="12" md="6">
                      <b-form-group :label="$t('product.attribute_name')" :label-for="`product-attribute-name${product.product_id}`">
                        <validation-provider #default="{ errors }" :name="$t('product.attribute_name')" rules="min:3">
                          <b-form-input :id="`product-attribute-name${product.product_id}`" v-model="product.attribute_name" :state="errors.length > 0 ? false : null" :placeholder="$t('product.attribute_name_placeholder')" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Ana Fiyat Listesi -->
                    <b-col cols="12" md="6">
                      <b-form-group :label="$t('product.main_price')" :label-for="`product-main-price-${product.product_id}`">
                        <b-input-group :id="`product-main-price-${product.product_id}`">
                          <cleave v-model="product.unit_price_tra_str" :options="cleaveOptions" class="form-control" />
                          <b-input-group-append v-if="currenciesReady">
                            <b-dropdown :text="getCurrencySymbol(product)" variant="outline-primary" right>
                              <b-dropdown-item
                                v-for="item in currencies"
                                :key="`currency_${product.product_code}_${item.currency_code}`"
                                @click="changeCurrency(product, item)"
                                :class="{
                                  'bg-light-primary': item.currency_code === product.currency
                                }"
                              >
                                {{ item.currency_code }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end justify-content-lg-start save-button">
                      <b-button variant="success" :disabled="invalid" @click="updateProduct(product.product_id)">
                        {{ $t('product.save') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card-body>
        </b-card>
      </section>
      <section v-else>
        <h3 class="mt-2">{{ $t('shop.not_found') }}</h3>
      </section>

      <!-- Pagination -->
      <section v-if="filteredProducts.length > 0">
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="page" :total-rows="filteredProductsCount" :per-page="shopPerPage" first-number align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <!-- <portal to="content-renderer-sidebar-detached-left">
        <product-filters :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" :showActivePassive="true" />
      </portal> -->
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BCardText, BFormInput, BInputGroup, BInputGroupAppend, BFormGroup, BFormCheckbox, BDropdown, BDropdownItem, BImg, BButton, BPagination, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full'

//import ProductFilters from "@/views/Order/Shop/ProductFilters.vue";
import ProductFilters from './ProductFilters.vue'
import { CHANGE_PER_PAGE, CHANGE_SHOP_PAGE_EVENT, CHANGE_SORT_TYPE, TYPE_SEARCH } from '@/store/services/shop-service'
import { mapGetters } from 'vuex'
import { FAST_PRODUCT_EDIT, GET_ALL_PRODUCT_FOR_FAST_EDIT, GET_BRANDS } from '@/store/services/product-service'
import { GET_ALL_CURRENCY_TYPES } from '@/store/services/currency-service'

export default {
  name: 'product-fast-edit',
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BImg,
    BButton,
    BPagination,
    BOverlay,

    Cleave,
    Treeselect,

    ValidationObserver,
    ValidationProvider,

    // SFC
    ProductFilters
  },
  directives: {
    Ripple
  },
  data() {
    return {
      ready: false,
      mqShallShowLeftSidebar: false,
      currencies: [],
      currenciesReady: false
    }
  },
  created() {
    this.$store.dispatch(GET_BRANDS)
    this.$store.dispatch(GET_ALL_CURRENCY_TYPES).then(response => {
      this.currencies = response
      this.currenciesReady = true
    })
    this.$store
      .dispatch(GET_ALL_PRODUCT_FOR_FAST_EDIT)
      .then(() => {
        this.$store.commit(CHANGE_PER_PAGE, 10)
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      })
      .then(() => {
        this.ready = true
      })
  },
  computed: {
    ...mapGetters(['filteredProducts', 'filteredProductsCount', 'shopSearch', 'shopPage', 'shopPerPage', 'getLocale', 'brandLogo', 'productImages', 'productBrands', 'productBrandsReady', 'shopSortTypes', 'selectedSortType']),
    search: {
      get() {
        return this.shopSearch
      },
      set(val) {
        this.$store.commit(TYPE_SEARCH, val)
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      }
    },
    page: {
      get() {
        return this.shopPage
      },
      set(val) {
        const rootEle = document.documentElement
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, val)
      }
    },
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    changeSortType(val) {
      this.$store.commit(CHANGE_SORT_TYPE, val)
      this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
    },
    openProduct(id) {
      let routeData = this.$router.resolve({
        name: '/Shop/Product',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    getProductImage(product) {
      let tempImages = JSON.parse(product.product_images ?? '[]')
      if (tempImages?.length) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${tempImages[0].image_id}`
      }
      return this.brandLogo
    },
    updateProductState(product) {
      product.temp_is_passive = !product.temp_is_passive
    },
    changeCategory(event, product_id) {},
    updateProduct(product_id) {
      this.$store
        .dispatch(FAST_PRODUCT_EDIT, product_id)
        .then(() => {})
        .catch(() => {})
    },
    getCurrencySymbol(product) {
      return this.currencies.find(x => x.currency_code === product.currency).symbol
    },
    changeCurrency(product, currency) {
      product.currency_id = currency.currency_id
      product.currency = currency.currency_code
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';

.sidebar-shop {
  z-index: 1000 !important;
}
.item-name {
  padding-right: 5px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';
.swiper-navigations {
  width: 100%;
}
.product-image-div {
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    &:hover {
      border-color: $primary !important;
    }
  }
}
.product-image {
  width: 100%;
  object-fit: cover;
  animation: all ease-in 1s;
  overflow: hidden;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.list-view {
  .product-detail {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
}

.item-actions-div {
  min-height: 50px;
}

.list-view {
  .item-actions-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
    .action-wishlist,
    .action-cart {
      min-height: 50px;
      height: 100%;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        min-height: 50px;
      }
    }
  }
}

.product-is-new {
  display: flex;
  position: absolute;
  top: 0.8rem;
  left: -5.2rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.save-button {
  margin-top: 4px;
}
</style>

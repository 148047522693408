var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"inherit"}},[_c('b-overlay',{attrs:{"show":!_vm.ready,"variant":"dark","opacity":"0.85","blur":"2px","rounded":"sm"}},[_c('section',{attrs:{"id":"ecommerce-header"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"ecommerce-header-items"},[_c('div',{staticClass:"result-toggler"},[(_vm.filteredProductsCount > 0)?_c('div',{staticClass:"search-results"},[_vm._v(_vm._s(_vm.filteredProductsCount)+" "+_vm._s(_vm.$t('shop.result_found')))]):_vm._e()]),_c('div',{staticClass:"view-options d-flex"},[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"text":_vm.$t(_vm.selectedSortType.value),"right":"","variant":"primary"}},_vm._l((_vm.shopSortTypes),function(sortOption){return _c('b-dropdown-item',{key:sortOption.value,on:{"click":function($event){return _vm.changeSortType(sortOption)}}},[_vm._v(" "+_vm._s(_vm.$t(sortOption.value))+" ")])}),1)],1)])])])]),_c('div',{staticClass:"ecommerce-searchbar mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"search-product",attrs:{"placeholder":_vm.$t('shop.search_placeholder')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}})],1)],1)],1)],1)],1),(_vm.filteredProducts.length > 0)?_c('section',{staticClass:"list-view"},_vm._l((_vm.filteredProducts),function(product,index){return _c('b-card',{key:("product_" + index + "_" + (product.product_code)),attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticClass:"w-100 m-0"},[_c('b-col',{staticClass:" d-flex justify-content-center align-items-center mb-2 mb-lg-0",attrs:{"cols":"12","lg":"5","xl":"3"}},[_c('div',{staticClass:"product-image-div mr-md-1"},[(product.is_new_product === 1)?_c('div',{staticClass:"product-is-new bg-light-success rounded",domProps:{"innerHTML":_vm._s(_vm.$t('shop.is_new'))}}):_vm._e(),_c('b-img',{staticClass:"cursor-pointer product-image",attrs:{"src":_vm.getProductImage(product)},on:{"click":function($event){return _vm.openProduct(product.product_id)}}})],1)]),_c('b-col',{attrs:{"cols":"12","lg":"7","xl":"9"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.code'),"label-for":("product-code-" + (product.product_id))}},[_c('b-form-input',{attrs:{"id":("product-code-" + (product.product_id)),"disabled":""},model:{value:(product.product_code),callback:function ($$v) {_vm.$set(product, "product_code", $$v)},expression:"product.product_code"}})],1)],1),_c('b-col',{attrs:{"cols":"8","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.barcode'),"label-for":("product-barcode-" + (product.product_id))}},[_c('validation-provider',{attrs:{"name":_vm.$t('product.barcode'),"rules":"min:3|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("product-barcode-" + (product.product_id)),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('product.barcode_placeholder')},model:{value:(product.barcode),callback:function ($$v) {_vm.$set(product, "barcode", $$v)},expression:"product.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4","md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.state'),"label-for":("product-state-" + (product.product_id))}},[_c('b-form-checkbox',{attrs:{"checked":!product.temp_is_passive,"id":("product-state-" + (product.product_id)),"switch":"","inline":""},on:{"change":function($event){return _vm.updateProductState(product)}}},[_c('span',{staticClass:"d-none d-lg-block"},[_vm._v(_vm._s(product.temp_is_passive ? _vm.$t('product.passive') : _vm.$t('product.active')))])])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.name'),"label-for":("product-name-" + (product.product_id))}},[_c('validation-provider',{attrs:{"name":_vm.$t('product.name'),"rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("product-name-" + (product.product_id)),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('product.name_placeholder')},model:{value:(product.product_name),callback:function ($$v) {_vm.$set(product, "product_name", $$v)},expression:"product.product_name"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.attribute_code'),"label-for":("product-atrribute-code-" + (product.product_id))}},[_c('validation-provider',{attrs:{"name":_vm.$t('product.attribute_code'),"rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("product-atrribute-code-" + (product.product_id)),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('product.attribute_code_placeholder')},model:{value:(product.attribute_code),callback:function ($$v) {_vm.$set(product, "attribute_code", $$v)},expression:"product.attribute_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.attribute_name'),"label-for":("product-attribute-name" + (product.product_id))}},[_c('validation-provider',{attrs:{"name":_vm.$t('product.attribute_name'),"rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("product-attribute-name" + (product.product_id)),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('product.attribute_name_placeholder')},model:{value:(product.attribute_name),callback:function ($$v) {_vm.$set(product, "attribute_name", $$v)},expression:"product.attribute_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('product.main_price'),"label-for":("product-main-price-" + (product.product_id))}},[_c('b-input-group',{attrs:{"id":("product-main-price-" + (product.product_id))}},[_c('cleave',{staticClass:"form-control",attrs:{"options":_vm.cleaveOptions},model:{value:(product.unit_price_tra_str),callback:function ($$v) {_vm.$set(product, "unit_price_tra_str", $$v)},expression:"product.unit_price_tra_str"}}),(_vm.currenciesReady)?_c('b-input-group-append',[_c('b-dropdown',{attrs:{"text":_vm.getCurrencySymbol(product),"variant":"outline-primary","right":""}},_vm._l((_vm.currencies),function(item){return _c('b-dropdown-item',{key:("currency_" + (product.product_code) + "_" + (item.currency_code)),class:{
                                'bg-light-primary': item.currency_code === product.currency
                              },on:{"click":function($event){return _vm.changeCurrency(product, item)}}},[_vm._v(" "+_vm._s(item.currency_code)+" ")])}),1)],1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-end justify-content-lg-start save-button",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"success","disabled":invalid},on:{"click":function($event){return _vm.updateProduct(product.product_id)}}},[_vm._v(" "+_vm._s(_vm.$t('product.save'))+" ")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}),1):_c('section',[_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('shop.not_found')))])]),(_vm.filteredProducts.length > 0)?_c('section',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination',{attrs:{"total-rows":_vm.filteredProductsCount,"per-page":_vm.shopPerPage,"first-number":"","align":"center","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }